<template>
  <header class="main-header container-fluid bg-white">
    <div class="row h-100 align-items-center mx-0" :class="{ 'justify-content-center': boxed, 'justify-content-between': !boxed }">
      <div class="col-auto d-flex">
        <div class="py-3">
          <Logo :to="{ name: 'projects.list' }" :disable-link="noLinkToHome">Vinland</Logo>
        </div>
      </div>
      <div class="col-auto d-flex align-items-center" v-if="!boxed">
        <div class="d-lg-none">
          <Hamburger class="col-auto d-flex align-items-center d-lg-none px-4"></Hamburger>
        </div>
        <AccountDropdown class="d-none d-lg-block" v-if="isLogged" />
        <div v-else class="ml-3 d-none d-lg-block">
          <router-link :to="{ name: 'login' }">Login</router-link>
        </div>
      </div>
      <div class="col" v-else></div>
    </div>
  </header>
</template>

<script>

import rolesMixin from '@/mixins/roles.js';

import Hamburger from '@/libs/Sidebar/components/Hamburger/CssHamburger.vue';
import AccountDropdown from '@/views/components/Account/AccountDropdown.vue';
import Logo from '@/views/components/Layout/Logo.vue';

export default {
  mixins: [rolesMixin],
  components: {
    AccountDropdown,
    Hamburger,
    Logo,
  },
  props: {
    boxed: {
      type: Boolean,
      default: false,
    },
    noLinkToHome: {
      type: Boolean,
      default: false,
    },
  },
};

</script>

<style lang="scss">

$main-header-border-bottom-color: #1C2023 !default;
$main-header-box-shadow-color: #1C2023 !default;

.main-header {
    position: relative;
    z-index: 10;
    box-shadow: 0 0 15px rgba($body-color, .2);
}

</style>
