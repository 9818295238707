<template>
  <ul class="menu-items-list">
    <template v-for="(item, i) in items">
      <li class="menu-items__item__container" :class="{ 'with-children': item.children && item.children.length > 0, active: item.active }" :key="'menu' + i">
        <MenuItem :item="item" :level="level" :key="level + '-' + i" />

        <menu-component v-if="item.children && item.children.length > 0" :active="item.active" :level="level + 1" :items="item.children" />
      </li>
    </template>
  </ul>
</template>

<script>

import MenuItem from './MenuItem.vue';

export default {
  name: 'menu-component',
  components: {
    MenuItem,
  },
  props: {
    level: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
};

</script>

<style lang="scss">

.menu-items-list {
  padding: 0;
  list-style: none;
  color: $layout-menu-font-color;
  user-select: none;

  .menu-items__item__container {
    padding: 0 10px;
    margin: 0 0 $spacer 0;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;

    &.with-children {
      cursor: pointer;

      & > .menu-items__item {
        &::after {
          display: block;
          width: 15px;
          height: 15px;
          background: transparent;
          content: '\f077';
          font-family: "Font Awesome 6 Free";
          font-weight: 900;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
          margin-right: 10px;
          margin-left: 10px;
          transition: transform 0.5s;
        }
      }

      & > .opened::after {
        transform: rotate(180deg);
        transition: transform 0.5s;
      }

      & > .active::after {
        display: none;
      }
    }

    &.active {
      & > .menu-items__item {
        font-weight: bold;
      }

      &::after {
        display: none;
      }

      &,
      &.with-children.active + .menu-items-list {
        height: auto;
      }
    }

    &.with-children > .opened + .menu-items-list {
      transition: height .3s ease-in;
      height: auto;
    }

    &.with-children > .menu-items-list > .menu-items__item__container:last-child {
      margin-bottom: 0;
    }
  }

  .menu-items__item__container > .opened + .menu-items-list {
    padding-top: $spacer;
  }

  .menu-items-list {
    overflow: hidden;
    height: 0;
    transition: height .3s ease-out;

    .menu-items__item__container {
      padding-left: 30px;
    }
  }
}
</style>
