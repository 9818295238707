<template>
  <Menu class="main-menu" :items="items" />
</template>

<script>

import Menu from '@/libs/Menu/components/Menu.vue';

export default {
  components: {
    Menu,
  },
  props: {
    isLogged: {
      type: Boolean,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};

</script>
