<template>
  <component :is="layout" />
</template>

<script>

export default {
  props: {
    layouts: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    layout () {
      if (this.name && this.layouts[this.name]) {
        return this.layouts[this.name];
      }

      return Object.values(this.layouts)[0];
    },
  },
};

</script>
