<template>
  <AccountDropdown :label="name" :actions="actions" />
</template>

<script>

import rolesMixin from '@/mixins/roles.js';

import AccountDropdown from '@/libs/OAuth2/components/AccountDropdown.vue';

export default {
  mixins: [rolesMixin],
  components: {
    AccountDropdown,
  },
  data () {
    return {
      actions: [
        { key: 'profile', link: { name: 'profile' }, label: 'Profilo' },
        { key: 'change-password', link: { name: 'change-password' }, label: 'Cambia password' },
        { key: 'logout', link: { name: 'logout' }, label: 'Logout' },
      ],
    };
  },
  computed: {
    name () {
      return this.$oauth2?.me?.firstName || 'Utente';
    },
  },
};

</script>
