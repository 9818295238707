<template>
  <div class="layout-modal justify-content-center align-content-center">
    <AppHeader boxed no-link-to-home/>
    <div class="page-container d-flex flex-column justify-content-center flex-grow-1">
      <router-view class="page py-5 flex-grow-1"/>
    </div>
  </div>
</template>

<script>

import AppHeader from '@/views/components/Layout/Header.vue';

export default {
  components: {
    AppHeader,
  },
};

</script>

<style lang="scss">

.layout-modal {
  .page-container {
    .page {
      position: relative;

      @include media-breakpoint-up("md") {
        // padding-top: 10% !important;
        // padding-left: 25%;
      }
    }
  }
}

</style>
