<template>
  <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      :class="[{ [`modal-${size}`]: size }, { [`modal-${fullscreen}`]: fullscreen }]"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <slot name="title">{{ title }}</slot>
          </h5>
          <slot name="modal-header-close">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close">
            </button>
          </slot>
        </div>
        <div class="modal-body" :class="{ 'shadow-inset': scrollbarVisible }">
          <slot name="default"></slot>
        </div>
        <div class="modal-footer">
          <slot name="modal-footer">
            <slot v-if="!!$scopedSlots.actions" name="actions" :ok="ok" :close="close"></slot>
            <div v-else class="w-100">
              <button class="btn btn-primary" @click="close">{{ btnLabel }}</button>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const bootstrap = typeof window !== `undefined` && import('bootstrap');

export default {
  props: {
    value: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    btnLabel: {
      type: String,
      default: 'Accept',
    },
    title: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      description: 'Modal size',
      default: '',
      validator(value) {
        const acceptedValues = ['', 'sm', 'lg', 'xl'];
        return acceptedValues.includes(value);
      },
    },
    fullscreen: {
      type: String,
      description: 'Modal fullscreen',
      default: '',
      validator(value) {
        const acceptedValues = [
          '',
          'fullscreen',
          'fullscreen-sm-down',
          'fullscreen-md-down',
          'fullscreen-lg-down',
          'fullscreen-xl-down',
          'fullscreen-xxl-down',
        ];
        return acceptedValues.includes(value);
      },
    },
  },
  data () {
    return {
      modal: null,
      scrollbarVisible: false,
    };
  },
  methods: {
    ok () {
      if (!this.readonly) {
        this.$emit('input', true);
      }

      this.close();
    },
    close () {
      this.modal.hide();
    },
    handleResize() {
      if (!this.$refs.modal) {
        return false;
      }

      const element = this.$refs.modal.querySelector('.modal-body');

      this.scrollbarVisible = element.scrollHeight > element.clientHeight;
    },
  },
  mounted () {
    bootstrap?.then(({ Modal }) => {
      this.modal = new Modal(this.$refs.modal);
      this.modal.show();
      this.$refs.modal.addEventListener('hidden.bs.modal', () => this.$emit('close'));
      const body = this.$refs.modal.querySelector('.modal-body');
      new ResizeObserver(() => this.handleResize()).observe(body);
      this.handleResize();
    });
  },
  beforeDestroy () {
    this.modal.hide();
  },
};

</script>

<style lang="scss">

.modal-header {
  .close {
    background: none;
    border: none;
    box-shadow: none;
  }
}

</style>
