<template>
  <div class="layout-complete overflow-hidden">
    <AppHeader/>
    <div class="flex-grow-1 d-flex flex-row app-content-wrapper">
      <div class="page-menu overflow-auto">
        <div class="mb-3 px-4 d-lg-none">
        </div>
        <Sidebar :is-logged="isLogged" :items="menuItems" />
      </div>
      <div class="page-container flex-grow-1 d-flex flex-column">
        <div v-if="loading">LOADING</div>
        <router-view v-else class="page flex-grow-1"/>
        <AppFooter />
      </div>
    </div>
    <ConfirmBox/>
    <ScanQrCodeModal v-if="$modals.qrCode" title="Trova un prodotto dal QR Code" @qr-code-found="goToProduct" @close="$closeModal('qrCode')" />
  </div>
</template>

<script>

import rolesMixin from '@/mixins/roles.js';
import modalsManagerMixin from '@/libs/Modals/mixins/manager.js';

import AppHeader from '@/views/components/Layout/Header.vue';
import AppFooter from '@/views/components/Layout/Footer.vue';
import ConfirmBox from '@/views/components/Layout/ConfirmBox.vue';
import Sidebar from '@/views/components/Layout/Sidebar.vue';

import ScanQrCodeModal from '@/views/components/Modals/ScanQrCodeModal.vue';

export default {
  mixins: [rolesMixin, modalsManagerMixin],
  components: {
    Sidebar,
    AppHeader,
    AppFooter,
    ConfirmBox,
    ScanQrCodeModal,
  },
  data () {
    return {
      loading: false,
      website: null,
    };
  },
  computed: {
    websiteId () {
      return this.$route.params.websiteId;
    },
    menuItems () {
      if (!this.websiteId) {
        return [
          { label: 'Dashboard', to: { name: 'home' }, icon: 'fas fa-tachometer-alt', active: this.$route.name === 'home' },

          /*
          { label: 'Websites', to: { name: 'websites', params: { websiteId: 5 } }, icon: 'fas fa-desktop', active: this.$route.path.startsWith('/websites') },
          */

          { label: 'Clienti', to: { name: 'customers' }, icon: 'fas fa-user', active: this.$route.path.startsWith('/customers') },

          /*
          { label: 'Ordini/Proposte', to: { name: 'orders' }, icon: 'fas fa-receipt', active: this.$route.path.startsWith('/orders') },
           */

          { label: 'Fornitori', icon: 'fas fa-user-tie', active: this.$route.path.startsWith('/suppliers'), children: [
            { label: 'Produttori', to: { name: 'suppliers.producers' }, icon: 'fas fa-industry', active: this.$route.path.startsWith('/suppliers/producers') },
            { label: 'Distributori', to: { name: 'suppliers.dealers' }, icon: 'fas fa-industry', active: this.$route.path.startsWith('/suppliers/dealers') },
            { label: 'Zona geografica', to: { name: 'suppliers.zones' }, icon: 'fas fa-box-open', active: this.$route.path.startsWith('/suppliers/zones') },
          ]},

          { label: 'Catalogo', icon: 'fas fa-archive', active: this.$route.path.startsWith('/products'), children: [
            { label: 'Prodotti', to: { name: 'products.list' }, icon: 'fas fa-wine-bottle', active: this.$route.name === 'products.list' },
            { label: 'Riassortimento', to: { name: 'products.restocking' }, icon: 'fas fa-plus', active: this.$route.name === 'products.restocking' },
            { label: 'Scarico manuale', to: { name: 'products.unloading' }, icon: 'fas fa-minus', active: this.$route.name === 'products.unloading' },
          ]},

          { label: 'Inventario', icon: 'fas fa-archive', active: this.$route.path.startsWith('/inventory'), children: [
            { label: 'Denominazioni di origine', to: { name: 'inventory.certifications' }, icon: 'fas fa-box-open', active: this.$route.path.startsWith('/inventory/certifications') },
            { label: 'Tipologie', to: { name: 'inventory.wine-types' }, icon: 'fas fa-leaf', active: this.$route.path.startsWith('/inventory/types') },
            { label: 'Tags', to: { name: 'inventory.tags' }, icon: 'fas fa-tags', active: this.$route.path.startsWith('/inventory/tags') },
            { label: 'Varietà di uva', to: { name: 'inventory.blends' }, icon: 'fas fa-leaf', active: this.$route.path.startsWith('/inventory/blends') },
            { label: 'Confezioni', to: { name: 'inventory.packages' }, icon: 'fas fa-box-open', active: this.$route.path.startsWith('/inventory/packages') },
            { label: 'Vini', to: { name: 'inventory.wines' }, icon: 'fa-solid fa-wine-glass', active: this.$route.path.startsWith('/inventory/wines') },
          ]},

          { label: 'Magazzino', icon: 'fas fa-warehouse', active: this.$route.path.startsWith('/warehouse'), children: [
            { label: 'Armadi', to: { name: 'warehouse.racks' }, icon: 'fas fa-pallet', active: this.$route.path.startsWith('/warehouse/racks') },
          ]},

          { label: 'Settings', icon: 'fas fa-cog', active: this.$route.path.startsWith('/settings'), children: [
            { label: 'Lingue', to: { name: 'settings.languages' }, icon: 'fas fa-language', active: this.$route.path.startsWith('/settings/languages') },
            { label: 'Valute', to: { name: 'settings.currencies' }, icon: 'fas fa-euro-sign', active: this.$route.path.startsWith('/settings/currencies') },
            { label: 'Paesi', to: { name: 'settings.countries' }, icon: 'fas fa-globe', active: this.$route.path.startsWith('/settings/countries') },
          ]},
        ];
      }

      return [
        { label: 'Website dashboard', to: { name: 'websites', params: { id: this.websiteId } }, icon: 'fas fa-tachometer-alt', active: this.$route.path.startsWith(`/dashboard`) },
        { label: 'Torna al menu generale', to: { name: 'home' }, icon: 'fas fa-tachometer-alt', active: this.$route.path.startsWith(`/dashboard`) },
      ];
    },
  },
  watch: {
    'websiteId': {
      handler (newVal) {
        if (!newVal) {
          this.website = null;

          return;
        }

        this.loading = true;
        this.website = { id: this.websiteId, name: 'NOME' };
        this.loading = false;
      },
      immediate: true,
    },
  },
  methods: {
    goToProduct (productId, cbOk, cbErr) {
      this.$router.push({ name: 'inventory.products.show', params: { id: productId } })
        .then(() => {
          cbOk();
        })
        .catch(() => {
          cbErr();
        })
      ;
    },
  },
};

</script>

<style lang="scss">

$sidebar-border-right-color: #1C2023 !default;
$sidebar-size-header-height-mobile: 82px !default;
$main-footer-height: 50px !default;

.layout-complete {
  .main-header {
    height: $sidebar-size-header-height-mobile;
  }

  .sidebar {
    min-width: 200px;
    border-right: 1px solid $sidebar-border-right-color;
  }

  .page {
    padding: 10px 25px;
    max-width: 100%;
    overflow: auto;
  }

  .app-content-wrapper {
    height: calc(100vh - (#{$sidebar-size-header-height-mobile + $main-footer-height}));
  }

  .sidebar {
    @include media-breakpoint-up("lg") {
      display: none !important;
    }
  }

  .page-menu {
    padding-top: 20px;
    z-index: 5;
    height: 100%;
    flex-shrink: 0;
    display: none;

    @include media-breakpoint-down(md) {
      background: white;
      position: fixed;
      top: $sidebar-size-header-height-mobile;
      left: 0;
      bottom: 0;
      right: 0;
      height: calc(100vh - $sidebar-size-header-height-mobile);
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      width: $menu-full-width;
      max-width: $menu-full-width-max;
      min-width: $menu-full-width-min;
    }
  }
}

body.show-mobile-menu {
  .layout-complete {
    .page-menu {
      display: block;
    }
  }
}

</style>
