import $axios from '@/libs/OAuth2/services/axios.js';
import md5 from 'md5';

const emailToMd5 = email => md5(email.toString().toLowerCase());
const defaultPerPage = 20;

export default {
  // Uploader
  uploadFile: async ({ objectUrl, modifiedAt, name, type: mime }, type, progressHandler = null) => {
    let file = await fetch(objectUrl).then(r => r.blob()).then(blobFile => new File([blobFile], name, { type: mime, lastModified: modifiedAt }));

    const formData = new FormData();
    formData.append('file', file);

    return $axios.post(type ? `/temporary-files/${type}` : '/temporary-files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: progressHandler,
    });
  },
  uploadBase64File: ({ content, modifiedAt, name, size, type: mime }, type, progressHandler = null) => $axios.post(type ? `/temporary-files/${type}` : '/temporary-files', {
    file: {
      name,
      content,
      modifiedAt,
      size,
      type: mime,
    },
  }, {
    onUploadProgress: progressHandler,
  }),

  // Change password
  getChangePasswordToken: email => $axios.post(`/users/${emailToMd5(email)}/changePasswordTokens`),
  changePasswordWithToken: (email, token, password) => $axios.post(`/users/${email}/password`, { token, password }),

  // My profile
  getMyProfile: () => $axios.get('/profile'),
  updateMyProfile: user => $axios.put('/profile', user),
  changeMyPassword: (password, newPassword) => $axios.post('/profile/password', { password, newPassword }),

  // Settings: countries
  listCountries: () => $axios.get('/countries'),
  fetchCountry: countryCode => $axios.get(`/countries/${countryCode}`),
  createCountry: country => $axios.post(`/countries`, country),
  updateCountry: (countryCode, country) => $axios.put(`/countries/${countryCode}`, country),
  deleteCountry: countryCode => $axios.delete(`/countries/${countryCode}`),
  getCountryProductPrices: (countryCode) => $axios.get(`/countries/${countryCode}/prices`),
  getCountryProductPricesAsCsv: (countryCode) => $axios.get(`/countries/${countryCode}/prices/csv`, {
    responseType: 'blob',
  }),

  // Settings: languages
  listLanguages: () => $axios.get('/languages'),
  fetchLanguage: code => $axios.get(`/languages/${code}`),
  createLanguage: language => $axios.post(`/languages`, language),
  updateLanguage: (code, language) => $axios.put(`/languages/${code}`, language),
  deleteLanguage: code => $axios.delete(`/languages/${code}`),

  // Settings: currencies
  listCurrencies: () => $axios.get('/currencies'),
  fetchCurrency: code => $axios.get(`/currencies/${code}`),
  createCurrency: currency => $axios.post(`/currencies`, currency),
  updateCurrency: (code, currency) => $axios.put(`/currencies/${code}`, currency),
  deleteCurrency: code => $axios.delete(`/currencies/${code}`),

  // Suppliers: dealers
  listDealers: (page = null, perPage = null, params = {}) => $axios.get('/dealers', {
    params: {
      ...params,
      page: page || 1,
      perPage: perPage || defaultPerPage,
    },
  }),
  listDealersByProducer: id => $axios.get(`/dealers?producer=${id}`),
  createDealer: dealer => $axios.post(`/dealers`, dealer),
  fetchDealer: id => $axios.get(`/dealers/${id}`),
  updateDealer: (id, dealer) => $axios.put(`/dealers/${id}`, dealer),
  addProducerToDealer: (dealerId, producerId) => $axios.post(`/dealers/${dealerId}/producers`, { id: producerId }),
  removeProducerFromDealer: (dealerId, producerId) => $axios.delete(`/dealers/${dealerId}/producers/${producerId}`),
  deleteDealer: id => $axios.delete(`/dealers/${id}`),

  // Suppliers: producers
  listProducers: (page = null, perPage = null, params = {}) => $axios.get('/producers', {
    params: {
      ...params,
      page: page || 1,
      perPage: perPage || defaultPerPage,
    },
  }),
  listProducersByDealer: id => $axios.get(`/producers?dealer=${id}`),
  createProducer: producer => $axios.post(`/producers`, producer),
  fetchProducer: id => $axios.get(`/producers/${id}`),
  updateProducer: (id, producer) => $axios.put(`/producers/${id}`, producer),
  addDealerToProducer: (producerId, dealerId) => $axios.post(`/producers/${producerId}/dealers`, { id: dealerId }),
  removeDealerFromProducer: (producerId, dealerId) => $axios.delete(`/producers/${producerId}/dealers/${dealerId}`),
  deleteProducer: id => $axios.delete(`/producers/${id}`),

  // Suppliers: zones
  listZones: (page = null, perPage = null, params = {}) => $axios.get('/zones', {
    params: {
      ...params,
      page: page || 1,
      perPage: perPage || defaultPerPage,
    },
  }),
  createZone: zone => $axios.post(`/zones`, zone),
  fetchZone: id => $axios.get(`/zones/${id}`),
  updateZone: (id, zone) => $axios.put(`/zones/${id}`, zone),
  deleteZone: id => $axios.delete(`/zones/${id}`),

  // Inventory: packages
  listPackages: (page = null, perPage = null, params = {}) => $axios.get('/packages', {
    params: {
      ...params,
      page: page || 1,
      perPage: perPage || defaultPerPage,
    },
  }),
  createPackage: packageObj => $axios.post(`/packages`, packageObj),
  fetchPackage: id => $axios.get(`/packages/${id}`),
  updatePackage: (id, packageObj) => $axios.put(`/packages/${id}`, packageObj),
  deletePackage: id => $axios.delete(`/packages/${id}`),

  // Inventory: blends
  listBlends: () => $axios.get('/blends'),
  createBlend: blend => $axios.post(`/blends`, blend),
  fetchBlend: id => $axios.get(`/blends/${id}`),
  updateBlend: (id, blend) => $axios.put(`/blends/${id}`, blend),
  deleteBlend: id => $axios.delete(`/blends/${id}`),

  // Inventory: wine types
  listWineTypes: () => $axios.get('/wine-types'),
  createWineType: wineType => $axios.post(`/wine-types`, wineType),
  fetchWineType: id => $axios.get(`/wine-types/${id}`),
  updateWineType: (id, wineType) => $axios.put(`/wine-types/${id}`, wineType),
  deleteWineType: id => $axios.delete(`/wine-types/${id}`),

  // Inventory: wines
  listWines: (page = null, perPage = null, params = {}) => $axios.get('/wines', {
    params: {
      ...params,
      page: page || 1,
      perPage: perPage || defaultPerPage,
    },
  }),
  getAllWines: (params = {}) => $axios.get('/wines', {
    params: {
      ...params,
      all: true,
      page: undefined,
      perPage: undefined,
    },
  }),
  createWine: wine => $axios.post(`/wines`, wine),
  fetchWine: id => $axios.get(`/wines/${id}`),
  updateWine: (id, wine) => $axios.put(`/wines/${id}`, wine),
  deleteWine: id => $axios.delete(`/wines/${id}`),
  addMainBlendToWine: (wineId, mainBlendId) => $axios.post(`/wines/${wineId}/main-blends/${mainBlendId}`),
  removeMainBlendFromWine: (wineId, mainBlendId) => $axios.delete(`/wines/${wineId}/main-blends/${mainBlendId}`),
  addSecondaryBlendToWine: (wineId, secondaryBlendId) => $axios.post(`/wines/${wineId}/secondary-blends/${secondaryBlendId}`),
  removeSecondaryBlendFromWine: (wineId, secondaryBlendId) => $axios.delete(`/wines/${wineId}/secondary-blends/${secondaryBlendId}`),
  addCertificationToWine: (wineId, certificationId) => $axios.post(`/wines/${wineId}/certifications/${certificationId}`),
  removeCertificationFromWine: (wineId, certificationId) => $axios.delete(`/wines/${wineId}/certifications/${certificationId}`),
  addTagToWine: (wineId, tagId) => $axios.post(`/wines/${wineId}/tags/${tagId}`),
  removeTagFromWine: (wineId, tagId) => $axios.delete(`/wines/${wineId}/tags/${tagId}`),

  // Inventory: products (wine packages)
  listWinePackages: (page = null, perPage = null, params = {}) => $axios.get('/products', {
    params: {
      ...params,
      page: page || 1,
      perPage: perPage || defaultPerPage,
    },
  }),
  getAllWinePackages: (params = {}) => $axios.get('/products', {
    params: {
      ...params,
      all: true,
      page: undefined,
      perPage: undefined,
    },
  }),
  createWinePackage: winePackage => $axios.post(`/products`, winePackage),
  fetchWinePackage: id => $axios.get(`/products/${id}`),
  fetchWinePackageLabels: (id, row = 0, column = 0, quantity = null) => $axios.get(`/products/${id}/labels?row=${row || 0}&column=${column || 0}&quantity=${quantity || ''}`, {
    responseType: 'blob',
  }),
  fetchWinePackageSingleLabel: (id) => $axios.get(`/products/${id}/single-labels`, {
    responseType: 'blob',
  }),
  updateWinePackage: (id, winePackage) => $axios.put(`/products/${id}`, winePackage),
  batchIncreaseWinePackageQuantities: quantities => $axios.post('/products/quantities/increments', { quantities }),
  batchDecreaseWinePackageQuantities: quantities => $axios.post('/products/quantities/decrements', { quantities }),

  // Inventory: certifications
  listCertifications: () => $axios.get('/certifications'),
  createCertification: certification => $axios.post(`/certifications`, certification),
  fetchCertification: id => $axios.get(`/certifications/${id}`),
  updateCertification: (id, certification) => $axios.put(`/certifications/${id}`, certification),
  deleteCertification: id => $axios.delete(`/certifications/${id}`),

  // Inventory: tags
  listTags: () => $axios.get('/tags'),
  createTag: tag => $axios.post(`/tags`, tag),
  fetchTag: id => $axios.get(`/tags/${id}`),
  updateTag: (id, tag) => $axios.put(`/tags/${id}`, tag),
  deleteTag: id => $axios.delete(`/tags/${id}`),

  // Warehouse: racks
  listRacks: () => $axios.get('/racks'),
  createRack: rack => $axios.post(`/racks`, rack),
  fetchRack: id => $axios.get(`/racks/${id}`),
  updateRack: (id, rack) => $axios.put(`/racks/${id}`, rack),
  deleteRack: id => $axios.delete(`/racks/${id}`),
  addProducerToRackShelf: (shelfId, producerId) => $axios.post(`/rack-shelves/${shelfId}/producers`, { id: producerId }),

  // Websites
  listWebsites: () => $axios.get('/websites'),
  fetchWebsite: id => $axios.get(`/websites/${id}`),
  updateWebsite: (id, website) => $axios.put(`/websites/${id}`, website),

  // Customers
  listCustomers: (page = null, perPage = null, params = {}) => $axios.get('/customers', {
    params: {
      ...params,
      page: page || 1,
      perPage: perPage || defaultPerPage,
    },
  }),
  createCustomer: customer => $axios.post(`/customers`, customer),
  fetchCustomer: id => $axios.get(`/customers/${id}`),
  updateCustomer: (id, customer) => $axios.put(`/customers/${id}`, customer),
  deleteCustomer: id => $axios.delete(`/customers/${id}`),

  // Orders
  listOrders: (page = null, perPage = null, params = {}) => $axios.get('/orders', {
    params: {
      ...params,
      page: page || 1,
      perPage: perPage || defaultPerPage,
    },
  }),
  fetchOrder: id => $axios.get(`/orders/${id}`),
};
