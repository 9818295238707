export default {
  baseUrl: `${process.env.BASE_URL || '/api/v1'}`,
  apiBaseUrl: `${process.env.VUE_APP_API_BASE_URL || '/api/v1'}`,
  STORAGE_KEY: 'app',
  tokenKey: 'app-token',
  refreshKey: 'app-refresh',
  client_id: `${process.env.VUE_APP_CLIENT_ID || 'app'}`,
  client_secret: `${process.env.VUE_APP_CLIENT_SECRET || null}`,
  env: `${process.env.NODE_ENV} || 'dev'`,
};
