import Vue from "vue";

/**
 * PLUGIN INITIALIZATION
 */
import UniqueId from 'vue-unique-id';
Vue.use(UniqueId);

import Log from './libs/Log/plugins/Log.js';
Vue.use(Log);

import Config from './libs/Config/plugins/Config.js';
Vue.use(Config);

import ApiVue from './libs/Api/plugins/Api';
import endpoints from './config/endpoints';
Vue.use(ApiVue, {
  endpoints,
});

import OAuth2Vue from './libs/OAuth2/plugins/OAuth2';
Vue.use(OAuth2Vue, {
  config: {
    client_id: Config._getService().get('client_id'),
    client_secret: Config._getService().get('client_secret'),
  },
});

import StorageVue from './libs/Storage/plugins/Storage';
Vue.use(StorageVue);

import Router from 'vue-router';
Vue.use(Router);

/**
 * APP INITIALIZATION
 */
import App from './views/App.vue';
import routerGenerator from './libs/Router/services/router';
import routes from './config/routes';
import guards from './config/guards';

Vue.config.productionTip = false;

new Vue({
  ...App,
  router: routerGenerator({ routes }, [guards]),
}).$mount("#app");

import './assets/scss/bootstrap.scss';
import './assets/scss/style.scss';
(() => import(/* webpackChunkName: "css-fontawesome" */ './assets/scss/fontawesome.scss'))();
