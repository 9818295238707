<template>
  <ConfirmModalContainer />
</template>

<script>

import ConfirmModalContainer from '@/libs/Confirm/components/ConfirmModalContainer.vue';

export default {
  components: {
    ConfirmModalContainer,
  },
};

</script>

<style lang="scss">

.confirm-box.modal {
  & ~ .modal-backdrop {
    background-color: rgba(0, 0, 0, .6);
  }

  .modal-header {
    display: flex;
    align-items: center;
    padding: 0;

    .modal-title {
      padding: 10px 1rem;
      font-size: 1.1rem;
    }

    .close {
      background: transparent;
      margin: 0;
      border: none;
      padding: 0 20px;
      font-size: 2rem;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .button-reject {
      margin-right: 20px;
    }
  }
}

</style>
