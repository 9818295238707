import { generateLogoutRoute } from '@/libs/OAuth2/routes/logoutRoute.js';
import { generateCatchallRoute } from '@/libs/Router/routes/catchallToRootRoute.js';

const onlyGuests = true;
const onlyLoggedIn = true;

export default [
  /* PUBLIC */
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/pages/Public/Login.vue'),
    meta: {
      layout: 'modal',
      guards: { onlyGuests },
    },
  },
  {
    path: '/password-reset/:email?',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "login" */ '@/views/pages/Public/PasswordReset.vue'),
    meta: {
      layout: 'modal',
    },
  },
  {
    path: '/password-reset/:email/change-password/:token',
    name: 'password-reset-change-password',
    component: () => import(/* webpackChunkName: "login" */ '@/views/pages/Public/ResetPasswordChange.vue'),
    meta: {
      layout: 'modal',
    },
  },

  /* USER PROFILE */
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/pages/Private/Dashboard/Dashboard.vue'),
    meta: {
      guards: { onlyLoggedIn },
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/pages/Private/MyProfile/ShowMyProfile.vue'),
    meta: {
      guards: { onlyLoggedIn },
    },
  },
  {
    path: '/profile/edit',
    name: 'profile.edit',
    component: () => import('@/views/pages/Private/MyProfile/EditMyProfile.vue'),
    meta: {
      guards: { onlyLoggedIn },
    },
  },
  {
    path: '/profile/password',
    name: 'change-password',
    component: () => import('@/views/pages/Private/MyProfile/ChangeMyPassword.vue'),
    meta: {
      guards: { onlyLoggedIn },
    },
  },

  {
    path: '/suppliers',
    component: () => import('@/libs/Router/components/InnerRouter.vue'),
    meta: {
      guards: { onlyLoggedIn },
    },
    children: [
      {
        path: 'dealers',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'suppliers.dealers',
            component: () => import('@/views/pages/Private/Suppliers/Dealers/ListDealers.vue'),
          },
          {
            path: 'new',
            name: 'suppliers.dealers.add',
            component: () => import('@/views/pages/Private/Suppliers/Dealers/AddDealer.vue'),
          },
          {
            path: ':id',
            name: 'suppliers.dealers.show',
            component: () => import('@/views/pages/Private/Suppliers/Dealers/ShowDealerDetails.vue'),
          },
          {
            path: ':id/edit',
            name: 'suppliers.dealers.edit',
            component: () => import('@/views/pages/Private/Suppliers/Dealers/EditDealer.vue'),
          },
        ],
      },
      {
        path: 'producers',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'suppliers.producers',
            component: () => import('@/views/pages/Private/Suppliers/Producers/ListProducers.vue'),
          },
          {
            path: 'new',
            name: 'suppliers.producers.add',
            component: () => import('@/views/pages/Private/Suppliers/Producers/AddProducer.vue'),
          },
          {
            path: ':id',
            name: 'suppliers.producers.show',
            component: () => import('@/views/pages/Private/Suppliers/Producers/ShowProducerDetails.vue'),
          },
          {
            path: ':id/edit',
            name: 'suppliers.producers.edit',
            component: () => import('@/views/pages/Private/Suppliers/Producers/EditProducer.vue'),
          },
        ],
      },
      {
        path: 'zones',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'suppliers.zones',
            component: () => import('@/views/pages/Private/Suppliers/Zones/ListZones.vue'),
          },
          {
            path: 'new',
            name: 'suppliers.zones.add',
            component: () => import('@/views/pages/Private/Suppliers/Zones/AddZone.vue'),
          },
          {
            path: ':id',
            name: 'suppliers.zones.show',
            component: () => import('@/views/pages/Private/Suppliers/Zones/ShowZoneDetails.vue'),
          },
          {
            path: ':id/edit',
            name: 'suppliers.zones.edit',
            component: () => import('@/views/pages/Private/Suppliers/Zones/EditZone.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/products',
    component: () => import('@/libs/Router/components/InnerRouter.vue'),
    meta: {
      guards: { onlyLoggedIn },
    },
    children: [
      {
        path: '',
        name: 'products.list',
        component: () => import('@/views/pages/Private/Inventory/Products/ListProducts.vue'),
      },
      {
        path: 'restocking',
        name: 'products.restocking',
        component: () => import('@/views/pages/Private/Inventory/Products/ProductRestocking.vue'),
      },
      {
        path: 'unloading',
        name: 'products.unloading',
        component: () => import('@/views/pages/Private/Inventory/Products/ProductUnloading.vue'),
      },
      {
        path: 'new',
        name: 'products.add',
        component: () => import('@/views/pages/Private/EmptyPage.vue'),
      },
      {
        path: ':id',
        name: 'products.show',
        component: () => import('@/views/pages/Private/Inventory/Products/ShowProductDetails.vue'),
      },
      {
        path: ':id/edit',
        name: 'products.edit',
        component: () => import('@/views/pages/Private/EmptyPage.vue'),
      },
    ],
  },
  {
    path: '/inventory',
    component: () => import('@/libs/Router/components/InnerRouter.vue'),
    meta: {
      guards: { onlyLoggedIn },
    },
    children: [
      {
        path: 'certifications',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'inventory.certifications',
            component: () => import('@/views/pages/Private/Inventory/Certifications/ListCertifications.vue'),
          },
          {
            path: 'new',
            name: 'inventory.certifications.add',
            component: () => import('@/views/pages/Private/Inventory/Certifications/AddCertification.vue'),
          },
          {
            path: ':id',
            name: 'inventory.certifications.show',
            component: () => import('@/views/pages/Private/Inventory/Certifications/ShowCertificationDetails.vue'),
          },
          {
            path: ':id/edit',
            name: 'inventory.certifications.edit',
            component: () => import('@/views/pages/Private/Inventory/Certifications/EditCertification.vue'),
          },
        ],
      },
      {
        path: 'packages',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'inventory.packages',
            component: () => import('@/views/pages/Private/Inventory/Packages/ListPackages.vue'),
          },
          {
            path: 'new',
            name: 'inventory.packages.add',
            component: () => import('@/views/pages/Private/Inventory/Packages/AddPackage.vue'),
          },
          {
            path: ':id',
            name: 'inventory.packages.show',
            component: () => import('@/views/pages/Private/Inventory/Packages/ShowPackageDetails.vue'),
          },
          {
            path: ':id/edit',
            name: 'inventory.packages.edit',
            component: () => import('@/views/pages/Private/Inventory/Packages/EditPackage.vue'),
          },
        ],
      },
      {
        path: 'blends',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'inventory.blends',
            component: () => import('@/views/pages/Private/Inventory/Blends/ListBlends.vue'),
          },
          {
            path: 'new',
            name: 'inventory.blends.add',
            component: () => import('@/views/pages/Private/Inventory/Blends/AddBlend.vue'),
          },
          {
            path: ':id',
            name: 'inventory.blends.show',
            component: () => import('@/views/pages/Private/Inventory/Blends/ShowBlendDetails.vue'),
          },
          {
            path: ':id/edit',
            name: 'inventory.blends.edit',
            component: () => import('@/views/pages/Private/Inventory/Blends/EditBlend.vue'),
          },
        ],
      },
      {
        path: 'wine-types',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'inventory.wine-types',
            component: () => import('@/views/pages/Private/Inventory/WineTypes/ListWineTypes.vue'),
          },
          {
            path: 'new',
            name: 'inventory.wine-types.add',
            component: () => import('@/views/pages/Private/Inventory/WineTypes/AddWineType.vue'),
          },
          {
            path: ':id',
            name: 'inventory.wine-types.show',
            component: () => import('@/views/pages/Private/Inventory/WineTypes/ShowWineTypeDetails.vue'),
          },
          {
            path: ':id/edit',
            name: 'inventory.wine-types.edit',
            component: () => import('@/views/pages/Private/Inventory/WineTypes/EditWineType.vue'),
          },
        ],
      },
      {
        path: 'tags',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'inventory.tags',
            component: () => import('@/views/pages/Private/Inventory/Tags/ListTags.vue'),
          },
          {
            path: 'new',
            name: 'inventory.tags.add',
            component: () => import('@/views/pages/Private/Inventory/Tags/AddTag.vue'),
          },
          {
            path: ':id',
            name: 'inventory.tags.show',
            component: () => import('@/views/pages/Private/Inventory/Tags/ShowTagDetails.vue'),
          },
          {
            path: ':id/edit',
            name: 'inventory.tags.edit',
            component: () => import('@/views/pages/Private/Inventory/Tags/EditTag.vue'),
          },
        ],
      },
      {
        path: 'wines',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'inventory.wines',
            component: () => import('@/views/pages/Private/Inventory/Wines/ListWines.vue'),
          },
          {
            path: 'new',
            name: 'inventory.wines.add',
            component: () => import('@/views/pages/Private/Inventory/Wines/AddWine.vue'),
          },
          {
            path: ':id',
            name: 'inventory.wines.show',
            component: () => import('@/views/pages/Private/Inventory/Wines/ShowWineDetails.vue'),
          },
          {
            path: ':id/edit',
            name: 'inventory.wines.edit',
            component: () => import('@/views/pages/Private/Inventory/Wines/EditWine.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/warehouse',
    component: () => import('@/libs/Router/components/InnerRouter.vue'),
    meta: {
      guards: { onlyLoggedIn },
    },
    children: [
      {
        path: 'racks',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'warehouse.racks',
            component: () => import('@/views/pages/Private/Warehouse/Racks/ListRacks.vue'),
          },
          {
            path: 'new',
            name: 'warehouse.racks.add',
            component: () => import('@/views/pages/Private/Warehouse/Racks/AddRack.vue'),
          },
          {
            path: ':id',
            name: 'warehouse.racks.show',
            component: () => import('@/views/pages/Private/Warehouse/Racks/ShowRackDetails.vue'),
          },
          {
            path: ':id/edit',
            name: 'warehouse.racks.edit',
            component: () => import('@/views/pages/Private/Warehouse/Racks/EditRack.vue'),
          },
        ],
      },
      {
        path: 'restocking',
        name: 'warehouse.restocking',
        component: () => import('@/views/pages/Private/EmptyPage.vue'),
      },
    ],
  },
  {
    path: '/orders',
    component: () => import('@/libs/Router/components/InnerRouter.vue'),
    meta: {
      guards: { onlyLoggedIn },
    },
    children: [
      {
        path: 'orders',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'orders',
            component: () => import('@/views/pages/Private/EmptyPage.vue'),
          },
          {
            path: ':id',
            name: 'orders.show',
            component: () => import('@/views/pages/Private/EmptyPage.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/customers',
    component: () => import('@/libs/Router/components/InnerRouter.vue'),
    meta: {
      guards: { onlyLoggedIn },
    },
    children: [
      {
        path: '',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'customers',
            component: () => import('@/views/pages/Private/Customers/ListCustomers.vue'),
          },
          {
            path: 'new',
            name: 'customers.add',
            component: () => import('@/views/pages/Private/EmptyPage.vue'),
          },
          {
            path: ':id',
            name: 'customers.show',
            component: () => import('@/views/pages/Private/Customers/ShowCustomerDetails.vue'),
          },
          {
            path: ':id/edit',
            name: 'customers.edit',
            component: () => import('@/views/pages/Private/EmptyPage.vue'),
          },
        ],
      },
    ],
  },

  {
    path: '/settings',
    component: () => import('@/libs/Router/components/InnerRouter.vue'),
    meta: {
      guards: { onlyLoggedIn },
    },
    children: [
      {
        path: 'languages',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'settings.languages',
            component: () => import('@/views/pages/Private/Settings/Languages/ListLanguages.vue'),
          },
          {
            path: 'add',
            name: 'settings.languages.add',
            component: () => import('@/views/pages/Private/Settings/Languages/AddLanguage.vue'),
          },
          {
            path: ':code/edit',
            name: 'settings.languages.edit',
            component: () => import('@/views/pages/Private/Settings/Languages/EditLanguage.vue'),
          },
        ],
      },
      {
        path: 'currencies',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'settings.currencies',
            component: () => import('@/views/pages/Private/Settings/Currencies/ListCurrencies.vue'),
          },
          {
            path: 'add',
            name: 'settings.currencies.add',
            component: () => import('@/views/pages/Private/Settings/Currencies/AddCurrency.vue'),
          },
          {
            path: ':code/edit',
            name: 'settings.currencies.edit',
            component: () => import('@/views/pages/Private/Settings/Currencies/EditCurrency.vue'),
          },
        ],
      },
      {
        path: 'countries',
        component: () => import('@/libs/Router/components/InnerRouter.vue'),
        children: [
          {
            path: '',
            name: 'settings.countries',
            component: () => import('@/views/pages/Private/Settings/Countries/ListCountries.vue'),
          },
          {
            path: 'add',
            name: 'settings.countries.add',
            component: () => import('@/views/pages/Private/Settings/Countries/AddCountry.vue'),
          },
          {
            path: ':code',
            name: 'settings.countries.show',
            component: () => import('@/views/pages/Private/Settings/Countries/ShowCountryDetails.vue'),
          },
          {
            path: ':code/prices',
            name: 'settings.countries.prices',
            component: () => import('@/views/pages/Private/Settings/Countries/ShowCountryPrices.vue'),
          },
          {
            path: ':code/edit',
            name: 'settings.countries.edit',
            component: () => import('@/views/pages/Private/Settings/Countries/EditCountry.vue'),
          },
        ],
      },
    ],
  },

  {
    path: '/website/:websiteId',
    component: () => import('@/libs/Router/components/InnerRouter.vue'),
    meta: {
      guards: { onlyLoggedIn },
    },
    children: [
      {
        path: '',
        name: 'website.dashboard',
        component: () => import('@/views/pages/Private/EmptyPage.vue'),
      },
    ],
  },

  generateLogoutRoute('login'),

  generateCatchallRoute('/'),
];
