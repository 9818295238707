<template>
  <DialogModal btnLabel="Aggiungi" :title="title" @close="$emit('close', $event)">
    <template #actions="{ close }">
      <div v-if="loaded" class="row w-100">
        <div v-if="barcode" class="col-auto">
          <button class="btn btn-secondary" @click="reset">Reset</button>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <button class="btn btn-primary" @click="closeModal(close)">Ok</button>
        </div>
      </div>
    </template>

    <template #default>
      <qrcode-stream v-if="!barcode" @decode="onDecode" @init="onInit"></qrcode-stream>

      <p v-if="loaded && barcode" class="barcode-found text-center">
        Qr Code trovato<br>{{ barcode }}
        <span v-if="!error"><br>Ricerca prodotto corrispondente...</span>
      </p>

      <p v-if="error"><strong>Prodotto non trovato</strong></p>

      <input ref="scanKeyboard" type="text" style="position: fixed; top: -100px; z-index: -50" @keyup.enter="scannedByKeyboard" @blur="(event) => event.target.focus()">
    </template>
  </DialogModal>
</template>

<script>

import { QrcodeStream } from 'vue-qrcode-reader';
import DialogModal from '@/libs/Modals/components/DialogModal.vue';

export default {
  components: { DialogModal, QrcodeStream },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      loaded: false,
      barcode: null,
      error: false,
    };
  },
  methods: {
    closeModal (close) {
      close();
    },

    onInit (promise) {
      promise
        .then(() => {
          this.loaded = true;
        })
        .finally(() => {
          this.$refs.scanKeyboard.focus();
        })
      ;
    },
    onDecode (barcode) {
      this.qrCodeFound(barcode);
    },

    scannedByKeyboard (event) {
      if (!this.loaded || this.barcode) {
        return;
      }

      this.qrCodeFound(event.target.value);
      event.target.value = '';
    },

    reset () {
      this.barcode = null;
    },
    qrCodeFound (qrCode) {
      this.error = false;
      this.barcode = qrCode;
      this.$emit('qr-code-found', this.barcode, this.productFound, this.productNotFound);
    },

    productFound () {
      this.$emit('close');
    },
    productNotFound () {
      this.error = true;
      this.loaded = false;
      this.barcode = null;

      setTimeout(() => {
        this.error = false;
      }, 2000);
    },
  },
};

</script>
