<template>
  <Loading v-if="isLoading" class="wrapper centered" />
  <Layout v-else :name="layout" :layouts="availableLayouts" class="wrapper centered" />
</template>

<script>

import checkGuardsMixin from '@/libs/OAuth2/mixins/checkGuards.js';
import layoutLoaderMixin from '@/libs/Layout/mixins/layoutLoader.js';
import checkGuards from '@/security/checkGuards.js';
import sidebar from '@/libs/Sidebar/services/sidebar.js';
import sidebarEffectorMixin from '@/libs/Sidebar/mixins/sidebarEffector.js';

import Layout from '@/libs/Layout/components/Layout.vue';
import Loading from '@/views/components/Layout/Loading.vue';
import ModalLayout from '@/views/layouts/LayoutModal.vue';
import CompleteLayout from '@/views/layouts/LayoutComplete.vue';

export default {
  mixins: [checkGuardsMixin, layoutLoaderMixin, sidebarEffectorMixin],
  components: {
    Loading,
    Layout,
  },
  data () {
    return {
      loading: true,
      checkGuards: route => checkGuards(route, this.$oauth2, this.$router.replace),
    };
  },
  computed: {
    isLoading () {
      return this.loading || !this.$route?.name;
    },
    availableLayouts () {
      return {
        default: CompleteLayout,
        modal: ModalLayout,
        renderless: null,
      };
    },
  },
  watch: {
    $route () {
      sidebar.close();
    },
  },
  mounted () {
    this.$oauth2
      .getMe()
      .finally(() => {
        this.loading = false;
      })
    ;

    this.$oauth2.addLogoutCallback(() => this.doCloseSidebar);
  },
};

</script>

<style lang="scss">

$min-width: 240px !default;

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-width: $min-width;
}

</style>
