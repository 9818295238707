<template>
  <div class="awl-loader" :class="containerClasses">
    <img v-if="imagePath" :src="imagePath" alt="Loading" :class="imageClasses" :style="imageStyles">
    <slot>
      <p>{{ text }}</p>
    </slot>
  </div>
</template>

<script>

import awlLoaderPropsMixin from '../mixins/awlLoaderProps.js';

export default {
  mixins: [awlLoaderPropsMixin],
  computed: {
    containerClasses () {
      const containerClasses = [];

      if (this.fitViewport) {
        containerClasses.push('vh-100 vw-100 position-fixed');
      } else {
        containerClasses.push('h-100 w-100');
      }

      if (this.centerContent) {
        containerClasses.push('d-flex align-items-center justify-content-center flex-column');
      }

      return containerClasses;
    },
    imageClasses () {
      const imageClasses = [];

      if (this.animated) {
        imageClasses.push('animated');
      }

      return imageClasses;
    },
    imageStyles () {
      const imageStyles = {};

      imageStyles['max.width'] = this.maxWidth;

      return imageStyles;
    },
  },
};

</script>

<style lang="scss">

.awl-loader {
  z-index: 99999;

  &.position-fixed {
    left: 0;
    top: 0;
  }

  img {
    max-width: 100px;
  }
}

</style>
