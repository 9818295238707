import { checkGuards } from '@/libs/OAuth2/security/routerGuards.js';

const checks = [
  (to, $oauth2, next) => {
    if (!!to?.meta?.guards?.onlyGuests && $oauth2?.me) {
      next({ name: 'home' });

      return true;
    }
  },
  (to, $oauth2, next) => {
    if (!!to?.meta?.guards?.onlyLoggedIn && !$oauth2?.me) {
      next({ name: 'login' });

      return true;
    }
  },
  (to, $oauth2, next) => {
    if (to?.meta?.guards?.roles) {
      const roles = Array.isArray(to.meta.guards.roles) ? to.meta.guards.roles : [];

      if (!roles.includes($oauth2?.me?.role)) {
        next({ name: 'home' });

        return true;
      }
    }
  },
];

export default function (newRoute, $oauth2, next) {
  return checkGuards(newRoute, $oauth2, next, checks);
}
