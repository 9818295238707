<template>
  <div v-if="item.children" class="menu-items__item d-flex flex-row flex-grow-1 align-items-center align-content-center" :class="{ opened: item.active || opened, active: item.active }" @click.prevent="toggle">
    <i class="menu-items__item__icon" :class="item.icon"></i>
    <span class="menu-items__item__label">{{ item.label }}</span>
  </div>
  <router-link v-else-if="item.to" class="menu-items__item d-flex flex-row text-decoration-none" :class="{ active: item.active }" :to="item.to">
    <i class="menu-items__item__icon" :class="item.icon"></i>
    <span class="menu-items__item__label">{{ item.label }}</span>
  </router-link>
  <div v-else class="menu-items__item d-flex flex-row">
    <i class="menu-items__item__icon" :class="item.icon"></i>
    <span class="menu-items__item__label">{{ item.label }}</span>
  </div>
</template>

<script>

export default {
  name: 'MenuItem',
  props: {
    level: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      opened: false,
    };
  },
  methods: {
    toggle () {
      if (this.item.active) {
        return;
      }
      this.opened = !this.opened;
    },
  },
};

</script>

<style lang="scss">

.menu-items__item {
  &__icon {
    flex-grow: 0;
    flex-shrink: 0;
    width: 20px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__label {
    flex-grow: 1;
  }
}

</style>
